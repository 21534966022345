if (window.flash.type !== null) {

    let common = {
        icon: window.flash.type,
        content: {
            element: 'div',
            attributes: {
                innerHTML: `${window.flash.message}`,
            },
        },
        title: window.messages[window.flash.type],
        button: {
            confirm: {
                text: window.messages.close,
                value: true,
                visible: true,
                className: 'btn-primary',
                closeModal: true
            }
        }
    }

    if (window.flash.type === 'success') {
        swal(common)
    }
    if (window.flash.type === 'info') {
        swal(common)
    }
    if (window.flash.type === 'warning') {
        swal({
            ...common, ...{
                dangerMode: true,
            }
        })
    }

    if (window.flash.type === 'danger' || window.flash.type === 'error') {
        swal({
            ...common, ...{
                icon: 'error',
                dangerMode: true,
            }
        })
    }
}
