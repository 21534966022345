APP.office = function ($) {

    "use strict";

    let formGroup;

    const initialize = function () {
        APP.Plugins.select2();
        APP.Plugins.dropify();
        codeFieldHandler();
    };


    const codeFieldHandler = function () {
        $('#code').on('blur', function () {
            formGroup = $(this).closest('.form-group');
            resetError();
            $.when(checkCode($(this).val())).then(function (response) {
                showError(response);
            });
        });
    };
    const checkCode = function (code) {
        return $.ajax({url: '/ajax/offices/check-code-exists', type: 'GET', data: {code: code}, dataType: 'json'});
    };

    const resetError = function () {
        formGroup.removeClass('has-error');
        formGroup.find('.help-block').html('');
    };

    const showError = function (response) {
        if (!response.exists) {
            return false;
        }
        formGroup.addClass('has-error').find('.help-block').html(response.exists);
    };

    return {
        init: function () {
            initialize();
        }
    }
}(window.jQuery);

$(() => APP.office.init())
