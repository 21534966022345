class SameTabOpenedDetector {

    constructor() {
        this.addBeforeUnloadListener()

        const pagesThatCanBeOpenInMultipleTabs = [
            'transactions',
            'expressnew',
            'express',
            'transfers',
            'history',
            'files'
        ]

        if (pagesThatCanBeOpenInMultipleTabs.includes(window.Aldego.page)) {
            return false
        }

        if (localStorage.getItem(window.Aldego.page)) {
            this.removeBeforeUnloadListener()
            this.showSwal()
        } else {
            localStorage.setItem(window.Aldego.page, new Date())
        }
    }

    showSwal() {
        swal({
            dangerMode: true,
            title: 'Onglets ouverts',
            text: 'La même page ouverte dans 2 différents onglets.',
            type: 'error',
            confirmButtonClass: 'btn-danger',
            button: 'Quitter',
            closeOnClickOutside: false,
            closeOnEsc: false,
        }).then(() => {
                window.close()
                //window.location.href = '/transactions'
            }
        )
    }

    removeBeforeUnloadListener() {
        let self = this
        window.removeEventListener('beforeunload', self.handleBeforeUnload)
    }

    addBeforeUnloadListener() {
        let self = this
        window.addEventListener('beforeunload', self.handleBeforeUnload)
    }

    handleBeforeUnload() {
        localStorage.removeItem(window.Aldego.page)
    }
}

new SameTabOpenedDetector()


export default {
    SameTabOpenedDetector
}
