window.APP = window.APP || {}

import './bootstrap'
import '../../node_modules/flag-icon-css/css/flag-icons.css'

// const moment = require('moment');
// moment.locale('fr');

document.addEventListener('DOMContentLoaded', function(){
    if (window.innerWidth < 992) {
        document.querySelectorAll('.navbar .dropdown').forEach(function(everyDropdown){
            everyDropdown.addEventListener('hidden.bs.dropdown', function () {
                // after dropdown is hidden, then find all submenus
                this.querySelectorAll('.submenu').forEach(function(everySubmenu){
                    // hide every submenu as well
                    everySubmenu.style.display = 'none'
                })
            })
        });

        document.querySelectorAll('.dropdown-menu a').forEach(function(element){
            element.addEventListener('click', function (e) {
                let nextEl = this.nextElementSibling
                if(nextEl && nextEl.classList.contains('submenu')) {
                    // prevent opening link if link needs to open dropdown
                    e.preventDefault();
                    if(nextEl.style.display === 'block'){
                        nextEl.style.display = 'none'
                    } else {
                        nextEl.style.display = 'block'
                    }
                }
            });
        })
    }
});
