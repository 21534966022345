APP.ContractsShow = function ($) {

    "use strict";

    const customerIdField = $('#customer_id')

    const initialize = function () {
        editCustomerIDHandler()
        editCustomerLocaleHandler()
    };

    const editCustomerIDHandler = () => {
        $('#edit-customer-id').off().on('click', function (e) {
            e.preventDefault();
            const holder = $('#edit-customer-id-field');
            holder.removeClass('d-none');
            holder.find('.btn-default').off().on('click', function (e) {
                e.preventDefault();
                holder.addClass('d-none');
            });
            holder.find('.btn-primary').off().on('click', function (e) {
                e.preventDefault();
                $.ajax({
                    url: '/ajax/update-customer-id',
                    type: 'post',
                    data: {contract_id: customerIdField.data('contract_id'), number: customerIdField.val()},
                    success: function (response) {
                        $('#customer_sig_id').text('#' + response.customer_id)
                        holder.addClass('d-none')
                    }
                })
            })
        })
    }

    const editCustomerLocaleHandler = () => {
        $('#edit-customer-locale').off().on('click', function (e) {
            e.preventDefault();
            const holder = $('#edit-customer-locale-field');
            holder.removeClass('d-none')
            holder.find('.btn-default').off().on('click', function (e) {
                e.preventDefault()
                holder.addClass('d-none')
            });
            holder.find('#customer-locale-btn').off().on('click', function (e) {
                e.preventDefault()
                $.ajax({
                    url: '/ajax/update-customer-locale',
                    type: 'post',
                    data: {lang: $(this).data('locale'), number: $(this).data('number')},
                    success: function (response) {
                        window.location.reload()
                    }
                })
            })
        })
    }

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(() => APP.ContractsShow.init());
