import './jquery-init'

const moment = require('moment')
import * as bootstrap from 'bootstrap'
import select2 from 'select2'
import dropify from 'dropify'
import swal from 'sweetalert'
import SameTabOpenedDetector from './lib/SameTabOpenedDetector'

window.bootstrap = bootstrap
window.select2 = select2
window.dropify = dropify
window.swal = swal

// if (window.language === 'fr') {
//     window.$.fn.datepicker.dates.fr = {
//         days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
//         daysShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
//         daysMin: ['d', 'l', 'ma', 'me', 'j', 'v', 's'],
//         months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
//         monthsShort: ['janv.', 'févr.', 'mars', 'avril', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
//         today: 'Aujourd\'hui',
//         monthsTitle: 'Mois',
//         clear: 'Effacer',
//         weekStart: 1,
//         format: 'dd/mm/yyyy'
//     }
// }

// bootstrap
$(window).on('load', () => {
    $('[data-bs-toggle="offcanvas"]').offcanvas()
    $('[data-bs-toggle="modal"]').modal()
    $('[data-bs-toggle="collapse"]').collapse()
    //$('[data-bs-toggle="tab"]').tabs()
})






