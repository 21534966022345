try {
    window.$ = window.jQuery = require('jquery')
    window.$.ajaxSetup({
        global: true,
        headers: {
            'X-CSRF-TOKEN': window.$('meta[name="csrf-token"]').attr('content')
        }, error: (event) => {
            if (typeof event.responseJSON !== 'undefined' && event.responseJSON.message === 'session_expired') {
                swal({
                    title: 'Session expirée',
                    text: 'Veuillez vous reconnecter',
                    type: 'error',
                    confirmButtonColor: 'rgb(242, 116, 116)',
                    button: 'Fermer'
                }).then(() => window.location.href = '/login')
            }
        }
    })
    window.$(document).ajaxStart(() => window.$('body').addClass('loading'))
    window.$(document).ajaxStop(() => window.$('body').removeClass('loading'))
} catch (e) {
    console.log('COULD NOT LOAD JQUERY')
}

// helpers
$.fn.extend({
    exists: function (callback) {
        return this.length > 0 ? callback($(this)) : false
    },
    count: function (obj) {
        if (!Object.keys) Object.keys = function (obj) {
            let arr = [], key
            for (key in obj) if (obj.hasOwnProperty(key)) arr.push(key)
            return arr
        }
        return Object.keys(obj).length
    },
    isEmpty: function (el) {
        return !el.val().replace(/^\s+/g, '').length // boolean (`true` if field is empty)
    },
    serializeForm: function () {
        var values = $(this).find('select, textarea').add($(this).find('input[type!=checkbox], input[type!=radio]')).serialize()
        $(this).find('input[type=checkbox]').each(function () {
            var chkVal = $(this).prop('checked') ? $(this).val() : '0'
            values += '&' + $(this).attr('name') + '=' + chkVal
        })
        return values
    },
})
$.log = (...args) => {
    if (process.env.MIX_APP_ENV === 'local') {
        console.dir(arguments)
        args.forEach(o => console.log(o))
    }
}
$.showSpinner = function () {
    $(this).closest('.form-group').find('.fa-spin').removeClass('d-none')
}
$.hideSpinner = function () {
    $('.fa-spin').addClass('d-none')
}
$.money = (num, decimal, floor) => {
    floor = floor || true
    decimal = decimal || 0 // <--- not working
    let locale = window.language === 'fr' ? 'fr-FR' : 'en-EN'
    let amount = floor ? Math.floor(num) : num
    let result = Number(amount.toFixed(0)).toLocaleString(locale)// + '.' + Number(amount.toString().slice(amount.toString().indexOf('.')+1)).toLocaleString(locale);
    return locale === 'fr-FR' ? result + '$' : '$' + result
}

$(() => {
    // check all
    $('input[type="radio"][data-group]').change(function () {
        $('input[type="radio"][data-group]:checked').not(this).prop('checked', false)
    })

    // disable user inputs on date fields
    $('input[type="date"]')
        .attr('pattern', '\d{4}-\d{2}-\d{2}')
        .on('keydown', () => false)
})
