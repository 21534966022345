APP.Plugins = function ($) {

    'use strict'

    return {
        init: function () {
        },

        offCanvasLoader: () => {
            $('[data-load-href]').exists((el) => {
                $(el).on('click', (e) => {
                    e.preventDefault()
                    $.ajax({
                        url: $(e.target).data('load-href'),
                        type: 'GET',
                    }).done((response) => {
                        $($(e.target).data('bs-target')).find('.offcanvas-body').html(response)
                    })
                })
            })
        },

        resetValues: function () {
            $('[data-plugin="reset-field-values"]').exists(function (el) {
                el.on('click', function (event) {
                    event.preventDefault()
                    let holder = $('#' + $(this).data('holder'))
                    holder.find(':input').not(':button, :submit, :reset, :hidden').each(function (i, e) {
                        $(e).val('').prop('checked', false).prop('selected', false)
                    })
                    holder.find('option[selected]').prop('selected', true)
                    holder.find('input[checked]').prop('checked', true)
                    holder.find('textarea').each(function (i, e) {
                        $(e).val($(e).html())
                    })
                })
            })
        },

        select2: function (options) {
            $('.select-single').exists(function (el) {
                el.select2({
                    ...options, ...{
                        theme: 'bootstrap-5',
                    }
                })
            })
            $('.select2').exists(function (el) {
                el.select2({
                    ...options, ...{
                        theme: 'bootstrap-5',
                    }
                })
            })
        },

        tabs: function () {
            $(document).on('click', '[data-bs-toggle="tab"]', function (e) {
                e.preventDefault()

                let holder = $(this).closest('.tabbable')
                holder.find('[data-bs-toggle="tab"]').closest('li').removeClass('active')
                $(this).closest('li').addClass('active')

                let target = $(this).attr('href')
                holder.find('.tab-pane').hide()
                holder.find('.tab-pane' + target).show()
            })
        },

        inputFile: function () {
            let element = '.input-group-file [type=file]'
            $(element).exists(function () {
                $(document).on('change', element, function () {
                    $(this).parents('.input-group-file').find('.form-control').val($.map($(this)[0].files, function (file) {
                        return file.name
                    }).join())
                })
            })
        },

        checkAll: function () {
            const element1 = '[type=checkbox].check-all'
            $(element1).exists(function (el) {
                $(document).on('change', element1, function () {
                    $('[name="' + el.data('connect') + '"').prop('checked', el.prop('checked'))
                })
            })

            const element2 = '.selectable-all'
            $(element2).exists(function () {
                $(document).on('change', element2, function () {
                    $(this).closest('table').find('input[type="checkbox"]').prop('checked', $(this).prop('checked'))
                })
            })
        },

        dropify: function () {
            $('[data-plugin="dropify"]').exists(function (el) {
                var data = {
                    messages: {
                        'default': 'Déplacer votre fichier ici ou cliquez',
                        'replace': 'Déplacer votre fichier ici ou cliquez pour remplacer',
                        'remove': 'Supprimer',
                        'error': 'Ooops, une erreur est survenue.'
                    }
                }
                el.dropify(window.language === 'fr' ? data : {})
            })
        },

        onErrorValidate: function () {
            const container = $('html, body')
            const scrollTo = $('.form-group.has-error').first()
            if (scrollTo.length) {
                container.animate({
                    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - 150
                })
            }
        },

        resetHandler: function () {
            $(document).on('click', '[type="reset"]', function (e) {
                e.preventDefault()
                $(this).closest('form').find('input, select, textarea').val('')
                $(this).closest('form').find('input[type="checkbox"],input[type="radio"]').prop('checked', false)
            })
        },

        markAsReadHandler: function () {
            $('#notification-mark-as-read').exists(function (el) {
                el.on('click', function (e) {
                    e.preventDefault()
                    $.ajax({
                        url: '/users/notifications/delete',
                        type: 'DELETE',
                        success: function () {
                            window.location.reload()
                        }
                    })
                })
            })
        },

        range: function () {
            $('[data-plugin="asRange"]').exists(function (el) {
                el.asRange({
                    format: function (value) {
                        return $.money(value)
                    },
                    onChange: function (value) {
                        $('[name="boat_value_range"]').attr('value', value[0] + ':' + value[1])
                    }
                })
            })
        },

        confirmBtn: function () {
            $('[data-alert-danger]').on('click', function (e) {
                e.preventDefault()
                let href = $(e.currentTarget).attr('href')

                swal({
                    title: window.messages.warning,
                    text: window.messages.not_current_term_continue,
                    icon: 'warning',
                    buttons: {
                        cancel: {
                            text: window.messages.cancel,
                            value: null,
                            visible: true,
                            closeModal: true,
                        },
                        confirm: {
                            text: window.messages.continue,
                            value: true,
                            visible: true,
                            className: 'bg-success',
                            closeModal: true
                        }
                    }
                }).then((result) => {
                    if (result) {
                        window.location.href = href
                    }
                })

                // swal({
                //     title: 'Attention',
                //     text: window.messages.not_current_term_continue,
                //     type: 'warning',
                //     showCancelButton: true,
                //     confirmButtonText: 'Confirmer',
                //     cancelButtonText: 'Annuler',
                //     closeOnConfirm: false
                // }).then(isConfirm => {
                //     if (isConfirm) {
                //         window.location.href = href
                //     }
                // })
            })
        },


        globalSearchForm: function () {
            $(document).on('keypress', '#global-search', function (e) {
                if (e.which === 13) {
                    $(this).submit()
                }
            })
        },

        showHideRadioHandler: function () {
            $('input[type="radio"][data-toggle]').exists(function (el) {
                el.each(function () {
                    $(this).on('change', function () {
                        if ($(this).prop('checked') === true && parseInt($(this).val()) > 0) {
                            $.log('SHOW ' + '#' + $(this).data('toggle'))
                            $('#' + $(this).data('toggle')).show()
                        } else {
                            $.log('HIDE ' + '#' + $(this).data('toggle'))
                            $('#' + $(this).data('toggle')).hide()
                        }
                    })
                })
                setTimeout(() => $('input[type="radio"][data-toggle]:checked').trigger('change'), 500)
            })
        },

        dualListBox: function () {
            $('select.dual-list').exists(function (el) {
                el.DualListBox({
                    json: false
                })
            })
        },

        dPicker: () => {
            const dPickers = $('[data-plugin="dPicker"]')

            const label = window.language === 'fr' ? ['Jour', 'Mois', 'Année'] : ['Day', 'Month', 'Year']
            const months = window.language === 'fr' ? ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'] : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

            dPickers.exists(() => {
                dPickers.each((index, el) => {
                    let input = $(el)
                    input.attr('type', 'hidden')
                    let holder = input.closest('.form-group')

                    let dateParts = input.val().split('-')
                    let y = dateParts[0]
                    let m = typeof dateParts[1] === 'undefined' ? '' : dateParts[1]
                    let d = typeof dateParts[2] === 'undefined' ? '' : dateParts[2]

                    let monthsOptions = months.map((month, i) => `<option value="${i + 1}"${m == (i + 1) ? ' selected="selected"' : ''}>${month}</option>`)

                    input.after(`<div class="d-flex justify-content-between dp" style="gap:15px">
                    <div class="flex-grow-1"><label class="form-label mb-0">${label[0]}</label><input maxlength="2" pattern="[0-9]{2}" type="text" min="1" max="31" class="form-control dpd" value="${d}"></div>
                    <div class="flex-grow-1"><label class="form-label mb-0">${label[1]}</label><input maxlength="2" pattern="[0-9]{2}" type="text" min="1" max="12" class="form-control dpm" value="${m}"></div>
<!--                    <div><label class="form-label mb-0">${label[1]}</label><select class="form-select dpm">${monthsOptions}</select></div>-->
                    <div class="flex-grow-1"><label class="form-label mb-0">${label[2]}</label><input maxlength="4" pattern="[0-9]{4}" type="text" min="1900" max="2222" class="form-control dpy" value="${y}"></div></div>`)

                    holder.find('.dpd, .dpm, .dpy').on('keydown', (e) => {

                        if (e.key.length === 1 && e.key.match(/[a-z]/i)) {
                            return false
                        }

                        $(e.target).removeClass('border-danger')

                        if ($(e.target).hasClass('dpy')) {
                            if (e.target.value.length > 4 && e.key !== 'Backspace' && e.key !== 'Tab') { // no more room
                                $(e.target).val(e.target.value.slice(0, -1))
                            }
                        }

                        if ($(e.target).hasClass('dpm')) {
                            if (e.target.value.length === 2 && e.key !== 'Backspace' && e.key !== 'Tab') { // no more room
                                holder.find('.dpy').focus()
                            }
                        }

                        if ($(e.target).hasClass('dpd')) {
                            if (e.target.value.length === 2 && e.key !== 'Backspace' && e.key !== 'Tab') { // no more room
                                holder.find('.dpm').focus()
                            }
                        }

                        let newDate = [
                            $(e.target).closest('.dp').find('.dpy').val(),
                            $(e.target).closest('.dp').find('.dpm').val(),
                            $(e.target).closest('.dp').find('.dpd').val()
                        ]
                        input.val(newDate.filter(i => i !== '').length === 3 ? newDate.join('-') : '')

                    }).on('blur', (e) => {
                        if ($(e.target).hasClass('dpy') && e.target.value !== '') {
                            const d = new Date()
                            let year = d.getFullYear()
                            if (e.target.value < (year - 100) || e.target.value > year) {
                                $(e.target).addClass('border-danger')
                            }
                        }

                        if ($(e.target).hasClass('dpm') && e.target.value !== '') {
                            if (e.target.value < 10) {
                                $(e.target).val(e.target.value.padStart(2, '0'))
                            }
                            if (e.target.value > 12 || e.target.value === '00') {
                                $(e.target).addClass('border-danger')
                                //e.target.value = 12
                            }
                        }

                        if ($(e.target).hasClass('dpd') && e.target.value !== '') {
                            if (e.target.value < 10) {
                                $(e.target).val(e.target.value.padStart(2, '0'))
                            }
                            if (parseInt(e.target.value) > 31 || e.target.value === '00') {
                                $(e.target).addClass('border-danger')
                                //e.target.value = 31
                            }
                        }

                        let newDate = [
                            $(e.target).closest('.dp').find('.dpy').val(),
                            $(e.target).closest('.dp').find('.dpm').val(),
                            $(e.target).closest('.dp').find('.dpd').val()
                        ]
                        input.val(newDate.filter(i => i !== '').length === 3 ? newDate.join('-') : '')

                    })
                })
            })
        },


        preventDoubleClick: () => {
            $('.no-double-click').on('click', function (e) {
                e.preventDefault()

                if ($(this).hasClass('clicked')) {
                    return false
                }
                $(this).addClass('clicked')
                return true
            })

            $('form').submit(function (e) {
                if ($(this).attr('id') === 'search-form') {
                    return true
                }
                if ($(this).attr('action') && $(this).attr('action').includes('reports/')) {
                    return true
                }
                $('button[type="submit"]', this).attr('disabled', true)
                return true
            })
        },
    }
}
(jQuery)

$(document).ready(function () {
    APP.Plugins.offCanvasLoader()
    APP.Plugins.onErrorValidate()
    APP.Plugins.resetHandler()
    APP.Plugins.markAsReadHandler()
    APP.Plugins.range()
    APP.Plugins.globalSearchForm()
    APP.Plugins.dualListBox()
    APP.Plugins.checkAll()
    APP.Plugins.showHideRadioHandler()
    APP.Plugins.resetValues()
    APP.Plugins.preventDoubleClick()
    APP.Plugins.dropify()
    APP.Plugins.confirmBtn()
    APP.Plugins.dPicker()
})
