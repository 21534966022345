APP.CanadianBankAccount = function ($) {

    "use strict";

    const initialize = function () {
        validateBankInformationHandler();
    };

    const validateBankInformationHandler = function () {
        $(document).on('click', '#validate-bank-account', function (e) {
            e.preventDefault();

            const transitNumber = $('#transit_number');
            const institutionNumber = $('#institution_number');
            const accountNumber = $('#account_number');

            institutionNumber.closest('.form-group').removeClass('has-error has-success').find('.help-block').text('');
            transitNumber.closest('.form-group').removeClass('has-error has-success').find('.help-block').text('');
            accountNumber.closest('.form-group').removeClass('has-error has-success').find('.help-block').text('');

            const tdBankAccount = new CanadianBankAccount({
                institution: institutionNumber.val(),
                transit: transitNumber.val(),
                account: accountNumber.val()
            });

            if (!tdBankAccount.isTransitValid()) {
                transitNumber.closest('.form-group').addClass('has-error').find('.help-block').hide().text(tdBankAccount.transitErrors()).fadeIn();
            }
            if (!tdBankAccount.isAccountValid()) {
                accountNumber.closest('.form-group').addClass('has-error').find('.help-block').hide().text(tdBankAccount.accountErrors()).fadeIn();
            }

            if (tdBankAccount.isTransitValid() && tdBankAccount.isAccountValid()) {
                $('#ppa-form').find('.bank-info').find('.form-group').addClass('has-success');
            }
        });
    };

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(() => APP.CanadianBankAccount.init())
