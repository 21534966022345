import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

if (window.location.toString().includes('hins')) {
    Pusher.logToConsole = true
}

if (process.env.MIX_PUSHER_APP_KEY && window.Aldego.contract_id) {

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.MIX_PUSHER_APP_KEY,
        cluster: process.env.MIX_PUSHER_APP_CLUSTER,
        forceTLS: true
    })

    let firstUser;

    let channel = window.Echo.join('modifying.' + window.Aldego.page)

    channel.here((members) => enter(members))
        .joining((member) => joined(member))
        .leaving((member) => left(member))
        .error((error) => failed(error))

    const enter = (members) => {
        if (members.length === 1) {

        } else {
            swal({
                content: true,
                dangerMode: true,
                title: 'Ce document est déjà en cours de modification',
                text: '',//firstUser.name + ', ' + firstUser.office, // first user on this page
                icon: 'error',
                confirmButtonClass: 'btn-danger',
                button: 'Quitter',
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then((callback) => {
                if (typeof callback !== 'undefined') {
                    window.location.href = '/transactions'
                }
            })
        }
    }

    const joined = (member) => {

    }

    const left = (member) => {
        if (channel.subscription.members.count === 1) {
            swal.close()
        }
    }

    const failed = (error) => console.log(error)
}
