APP.ajaxPager = function ($) {

    'use strict'

    // The <ul class="pagination">
    const pager = $('.ajax-pager')

    // Should be the <element id="ajax">
    const container = $('#ajax')
    const table = container.closest('table')

    // The search field at the top of the table
    const searchField = $('input[name="term"]')
    const statusField = $('select[name="status_id"]')
    const boatTypeField = $('select[name="boat_type_id"]')
    const activityTypeField = $('select[name="activity"]')

    // if the rows header are clicked we'll set the variable
    let orderBy = null

    // default sorting
    let sort = null

    // init functions
    const initialize = function () {
        if (pager.length === 0) {
            return false
        }
        popStateListener()
        pageChangeListener()
        searchFormListener()
        searchOrderListener()
        resetButtonListener()

        // dev
        //addColumnVisibilityToggle();
    }

    // Listen for history change
    const popStateListener = function () {
        $(window).bind('popstate', function (e) {
            // if the foward/back buttons are clicked, reload the next/last page
            load(window.location.href, false)
            // Reassign the search value if exists
            let term = /term=([^&]+)/.exec(window.location.href)

            if (term === null) {
                return false
            }

            searchField.val((term[1] ? decodeURIComponent(term[1]) : ''))
        })
    }

    // Listen for a search submission
    const searchFormListener = function () {
        $('#search-form').on('submit', function (e) {
            e.preventDefault()
            load(rebuildURI(), true)
        })
    }

    // Listen for a search submission
    const searchOrderListener = function () {
        $(document).on('click', '.order-results', function (e) {
            e.preventDefault()
            sort = orderBy === $(this).data('order') ? (sort === 'asc' ? 'desc' : 'asc') : 'asc'

            orderBy = $(this).data('order')
            // load data
            load(rebuildURI(), true)
        })
    }

    const rebuildURI = function () {

        let formInputs = {}
        let searchForm = $('#search-form')
        searchForm.find('input[type="text"],input[type="search"],select').each(function (i, el) {
            formInputs[$(this).attr('name')] = $(this).val() ? $(this).val() : undefined
        })

        searchForm.find('input[type="checkbox"],input[type="radio"]').each(function (i, el) {
            if ($(this).prop('checked')) {
                formInputs[$(this).attr('name')] = $(this).val() ? $(this).val() : undefined
            }
        })

        let params = Object.fromEntries(new URLSearchParams(location.search))

        let url = {
            page: orderBy ? params.page : 1,
            invoiced: $('[name="invoiced"]').prop('checked') ? true : null,
            not_invoiced: $('[name="not_invoiced"]').prop('checked') ? true : null,
            ready_to_invoice: $('[name="ready_to_invoice"]').prop('checked') ? true : null,
            not_ready_to_invoice: $('[name="not_ready_to_invoice"]').prop('checked') ? true : null,
            term: searchField.val() ? searchField.val() : null,
            status_id: statusField.val() ? statusField.val() : null,
            boat_type_id: boatTypeField.val() ? boatTypeField.val() : null,
            activity: activityTypeField.val() ? activityTypeField.val() : null,
            order: orderBy ? orderBy : params.order,
            sort: sort ? sort : (location.pathname === '/renewals' ? 'asc' : 'desc')
        }

        let newurl = window.location.pathname + '?' + $.param({...url, ...formInputs})

        $('#export-button').attr('href', newurl + '&export=1')

        return newurl
    }

    // When the user change the page using the paginator bar
    const pageChangeListener = function () {
        $(document).on('click', '.pagination a', function (e) {
            load($(this).attr('href'), true)
            e.preventDefault()
        })
    }

    const resetButtonListener = function () {
        $('button[type="reset"]').on('click', function () {
            let url = window.location;
            window.history.pushState(null, '', url.pathname)
        })
    }

    // load data
    const load = function (url, push) {
        $.when(callAPI(url)).then(function (data) {
            updatePage(data)
            if (push) {
                history.pushState(null, '', url)
            }
        }).fail(function () {
            alert('Data could not be loaded.')
        })
    }

    const callAPI = (url) => $.ajax({url: url, type: 'GET', dataType: 'json', cache: false})

    const updatePage = function (data) {
        container.html(data.html)
        pager.html(data.pager)
        $('.pager-currentpage').text(data.currentpage)
        $('.pager-lastpage').text(data.lastpage)
        $('.pager-total').text(data.total)

        $('ul.pagination').find('a').each(function () {
            let url = $(this).attr('href')
            //$(this).attr('href', location.search.replace(/[&|\?]page=\d+/g, '').replace(/^\?/, '&'));
        })
    }

    const addColumnVisibilityToggle = () => {
        table.wrap(`<div id="ajax-pager"/>`)

        // Get the table headers
        let columns = table.find('thead th')
            .filter((i, item) => $.trim($(item).text()) !== '')
            .map((i, item) => {
                return `<li><label class="dropdown-item filter-column-checkboxes" href=""><input class="form-check-input me-1" data-index="${$(item).index()}" type="checkbox" checked> ${$(item).text()}</label></li>`
            }).get().join('')

        $('#ajax-pager').prepend(`<div class="d-flex justify-content-between"><div></div><div><div class="dropdown"><a class="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Columns</a><ul class="dropdown-menu dropdown-menu-end">${columns}</ul></div></div></div></div>`)

        checkboxFilterHandler()
    }

    const checkboxFilterHandler = () => {
        $(document).on('click', '.filter-column-checkboxes', (e) => {
            let idx = $(e.target).data('index') + 1
            table.find('td:nth-child(' + idx + '),th:nth-child(' + idx + ')').toggle()
        })
    }

    return {
        init: () => initialize(),
        updatePage: (data) => updatePage(data),
    }
}(jQuery)

$(document).ready(function () {
    APP.ajaxPager.init()
})
